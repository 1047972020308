body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: url('assets/bg.jpg') no-repeat center center fixed;
  background-size: cover; */
  /* display: none; */
}

.patientRatio {
  font-size: 0.8rem !important;
}

.deceaseNotification {
  font-size: 0.8rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.trends-widget {
  width: 100%;      
  max-width: 600px;
  height: 400px;
  margin: 20px auto;
  border: 1px solid #ccc;
}

.chartjs-render-monitor {
  background-color: white;
}

#epidemicReportTable {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

#epidemicReportTable th, #epidemicReportTable td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

#epidemicReportTable th {
  background-color: #f2f2f2;
}

/* Make the first two rows in thead sticky */
#epidemicReportTable thead tr th {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 3;
}

/* Make the first column in thead and tbody sticky */
#epidemicReportTable tbody tr th:first-child,
#epidemicReportTable tbody tr td:first-child {
  position: sticky;
  left: 0;
  background-color: white; /* Adjust this color as needed */
  z-index: 2;
}

.MuiTypography-h5 {
  font-size: 1.2rem !important;
}

.MuiTypography-h6 {
  font-size: 1rem !important;
}

.MuiTypography-body1 {
  font-size: 0.8rem !important;
}

.lightgrey { background-color: 'lightgrey'; color: white;}
.green { background-color: #30bf30; color: white;}
.yellow { background-color: #ffe414; color: white;}
.orange { background-color: rgb(204, 133, 0); color: white;}
.red { background-color: #a30707; color: white;}

* {
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: #888 #e1e1e1; /* Thumb and track color */
}

::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
  height: 6px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color and style of the scrollbar's thumb */
  border-radius: 10px; /* Roundness of the scrollbar's thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar's thumb on hover */
}